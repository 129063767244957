export enum Brand {
  Default = 'avelon',
  WAGO = 'wago'
}

export const setBrandThemeColors = (): void => {
  const themingBrand = getThemingBrand();
  if (themingBrand !== Brand.Default) {
    updateTheme(themingBrand);
  }
};

export const getThemingBrand = (): Brand => {
  if (location.hostname.includes('building.wago.cloud') || new URLSearchParams(location.search).has('wago')) {
    return Brand.WAGO;
  }

  return Brand.Default;
};

const updateTheme = (brand: Brand): void => {
  const themeName = `${brand}-theme`;
  const themeFile = `assets/themes/${brand}.css`;
  const style = document.createElement('link');
  style.id = themeName;
  style.rel = 'stylesheet';
  style.type = 'text/css';
  style.href = themeFile;
  document.head.appendChild(style);
  updateDefaultColorPalette(brand);
};

const ColorPaletteToSequenceMap = [0, 2, 7, 4, 6, 1, 3, 8, 5, 9];

export const DefaultColorPalette = ['#3599B8', '#8AD4EB', '#01B8AA', '#AAD97F', '#F2C80F', '#FE9666', '#FD625E', '#A66999', '#DFBFBF', '#5F6B6D'];
export const DefaultColorSequence = ColorPaletteToSequenceMap.map(i => DefaultColorPalette[i]);

const updateDefaultColorPalette = (brand: Brand): void => {
  if (brand === Brand.WAGO) {
    DefaultColorPalette[0] = '#46738f';
    DefaultColorPalette[1] = '#69addb';
    DefaultColorPalette[2] = '#36b6aa';
    DefaultColorPalette[3] = '#6ec800';
    DefaultColorPalette[4] = '#ffe70a';
    DefaultColorPalette[5] = '#eb600a';
    DefaultColorPalette[6] = '#bb0522';
    DefaultColorPalette[7] = '#bca3ce';
    DefaultColorPalette[8] = '#f29b92';
    DefaultColorPalette[9] = '#626973';
  }

  ColorPaletteToSequenceMap.forEach(
    (paletteIndex: number, sequenceIndex: number) => (DefaultColorSequence[sequenceIndex] = DefaultColorPalette[paletteIndex])
  );
};
